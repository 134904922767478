import { useContext, useEffect } from 'react'

import type { NextPage } from 'next'
import { useRouter } from 'next/router'

import { SectionUserContext } from 'common/contexts/SectionUserContext'

// The index redirects to the user's default account's overview page.
const IndexRedirecter: NextPage = () => {
  const router = useRouter()
  const { selectedAccount, sectionUser } = useContext(SectionUserContext)

  useEffect(() => {
    if (sectionUser?.accounts && sectionUser?.accounts?.length > 0) {
      router.push(`/overview/account/${selectedAccount}`)
    }
  }, [sectionUser, router, selectedAccount])

  return null
}

export default IndexRedirecter
